<template>
  <div class="card-info-compta">
    <v-btn
      small
      class="btn-add-setting mr-1"
      @click.prevent="handelModalInfo('show')"
    >
      <font-awesome-icon icon="copy" class="mr-3" /> Copier l'information
    </v-btn>
    <v-dialog
      v-model="infoModal"
      persistent
      max-width="990px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add custom-vuetify-dialog-info-conta"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Mettre àjour les données comptabilité
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handelModalInfo('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
          <!-- COMPNAY TO USE  -->
          <v-row class="mt-4">
            <!-- SOCIETE -->
            <v-autocomplete
              @change="setComptaData"
              :clearable="true"
              v-model="filiale_id"
              :items="getFiliaeOfResponsableCopy"
              item-text="name"
              item-value="id"
              color="#704ad1"
              item-color="#704ad1"
              class="mb-1"
              placeholder="Séléctionnez"
              :loading="isLoadingS"
              :persistent-placeholder="true"
              label="Société"
              return-object
              required
              dense
              :auto-focus="false"
              no-data-text="Aucune société trouvée"
              outlined
              :menu-props="{
                bottom: true,
                offsetY: true
              }"
            >
            </v-autocomplete>
          </v-row>
          <!-- COMPTA DATA  -->
          <div class="card-style" v-if="info">
            <span class="title-card">Comptabilité</span>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  label="Journal vente"
                  mess
                  :persistent-placeholder="true"
                  v-model="info.vente.journal"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="Journal achat"
                  mess
                  :persistent-placeholder="true"
                  v-model="info.achat.journal"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Client : Compte generale "
                  mess
                  :persistent-placeholder="true"
                  v-model="info.customer"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Fournisseur : Compte generale "
                  mess
                  :persistent-placeholder="true"
                  v-model="info.supplier"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  label="Compte Prestation vente"
                  mess
                  :persistent-placeholder="true"
                  v-model="info.vente.prestation"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Compte Marchandise vente"
                  mess
                  :persistent-placeholder="true"
                  v-model="info.vente.marchandise"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Compte Prestation achat"
                  mess
                  :persistent-placeholder="true"
                  v-model="info.achat.prestation"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Compte Marchandise achat"
                  mess
                  :persistent-placeholder="true"
                  v-model="info.achat.marchandise"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="card-style" v-if="info">
            <span class="title-card">Prime délégataire</span>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Journal "
                  mess
                  :persistent-placeholder="true"
                  v-model="info.prime.journal"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Compte prime"
                  mess
                  :persistent-placeholder="true"
                  v-model="info.prime.compte"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <!-- TVA BLOCK  -->
          <div class="card-style" v-if="info">
            <span class="title-card">Gestion des TVA</span>
            <v-row v-for="(tva, index) in info.tva" :key="index">
              <v-col cols="2">
                <v-text-field
                  label="Tva *"
                  v-model="tva.valeur"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  :rules="[v => !!v || 'Tva est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Compte tva vente"
                  v-model="tva.compte_vente"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>

              <v-col cols="2">
                <v-text-field
                  label="compte marchandise(HT) "
                  v-model="tva.compte_marchandise"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Compte prestation(ht) "
                  v-model="tva.compte_prestation"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-checkbox
                  label="Activer"
                  class="checked-tva margin-none"
                  color="#704ad1"
                  :value="1"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  v-model="tva.is_active"
                ></v-checkbox>
              </v-col>
              <v-col cols="1">
                <font-awesome-icon
                  icon="times"
                  class="time-icon-style"
                  @click.prevent.stop="deleteTva(index)"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handelValidData"
            :loading="isLoading"
            :disabled="isLoading"
            :class="{ loader: isLoading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handelModalInfo('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: { dataToUse: { required: true } },
  data() {
    return {
      infoModal: false,
      info: null,
      error: null,
      isLoading: false,
      isLoadingS: false,
      filiale_id: null
    };
  },
  computed: {
    ...mapGetters(['getFiliaeOfResponsableCopy'])
  },
  methods: {
    ...mapActions([
      'getFilialsOfConnectedResponsableCoy',
      'getOneFilialeDataCompta'
    ]),
    async handelModalInfo(action) {
      if (action == 'show') {
        this.info = JSON.parse(JSON.stringify(this.dataToUse));
        this.infoModal = true;
        this.isLoadingS = true;
        await this.getFilialsOfConnectedResponsableCoy();
        this.isLoadingS = false;
      } else {
        this.infoModal = false;
        this.resetModal();
      }
    },

    async setComptaData() {
      if (this.filiale_id != null) {
        //   JOURNAL PRIME
        this.info.prime.journal =
          this.info.prime.journal == null ||
          this.info.prime.journal == 'null' ||
          this.info.prime.journal == ''
            ? this.filiale_id.prime.journal
            : this.info.prime.journal;
        // COMPTE PRODUCT PRIME
        this.info.prime.compte =
          this.info.prime.compte == null ||
          this.info.prime.compte == 'null' ||
          this.info.prime.compte == ''
            ? this.filiale_id.prime.compte
            : this.info.prime.compte;
        //   JOURNAL VENTE
        this.info.vente.journal =
          this.info.vente.journal == null ||
          this.info.vente.journal == 'null' ||
          this.info.vente.journal == ''
            ? this.filiale_id.vente.journal
            : this.info.vente.journal;
        // COMPTE MARCHANDISE VENTE
        this.info.vente.marchandise =
          this.info.vente.marchandise == null ||
          this.info.vente.marchandise == 'null' ||
          this.info.vente.marchandise == ''
            ? this.filiale_id.vente.marchandise
            : this.info.vente.marchandise;
        // COMPTE PRESTATION VENTE
        this.info.vente.prestation =
          this.info.vente.prestation == null ||
          this.info.vente.prestation == 'null' ||
          this.info.vente.prestation == ''
            ? this.filiale_id.vente.prestation
            : this.info.vente.prestation;
        //   JOURNAL  ACHAT
        this.info.achat.journal =
          this.info.achat.journal == null ||
          this.info.achat.journal == 'null' ||
          this.info.achat.journal == ''
            ? this.filiale_id.achat.journal
            : this.info.achat.journal;
        // COMPTE MARCHANDISE ACHAT
        this.info.achat.marchandise =
          this.info.achat.marchandise == null ||
          this.info.achat.marchandise == 'null' ||
          this.info.achat.marchandise == ''
            ? this.filiale_id.achat.marchandise
            : this.info.achat.marchandise;
        // COMPTE PRESTATION ACHAT
        this.info.achat.prestation =
          this.info.achat.prestation == null ||
          this.info.achat.prestation == 'null' ||
          this.info.achat.prestation == ''
            ? this.filiale_id.achat.prestation
            : this.info.achat.prestation;
        //C. COMPT GENERAL
        this.info.customer =
          this.info.customer == null ||
          this.info.customer == 'null' ||
          this.info.customer == ''
            ? this.filiale_id.customer
            : this.info.customer;
        //F.COMPTE GENERALE
        this.info.supplier =
          this.info.supplier == null ||
          this.info.supplier == 'null' ||
          this.info.supplier == ''
            ? this.filiale_id.supplier
            : this.info.supplier;
        //TVA
        let tva_existe = [...this.info.tva];
        tva_existe = tva_existe.map(i => i.valeur);
        this.filiale_id.tva.forEach(element => {
          if (tva_existe.includes(element.valeur)) {
            let tvaSelectedIndex = this.info.tva.findIndex(
              i => i.valeur == element.valeur
            );
            if (tvaSelectedIndex >= 0) {
              this.info.tva[tvaSelectedIndex].compte_achat =
                this.info.tva[tvaSelectedIndex].compte_achat == null ||
                this.info.tva[tvaSelectedIndex].compte_achat == 'null'
                  ? element.compte_achat
                  : this.info.tva[tvaSelectedIndex].compte_achat;
              this.info.tva[tvaSelectedIndex].compte_vente =
                this.info.tva[tvaSelectedIndex].compte_vente == null ||
                this.info.tva[tvaSelectedIndex].compte_vente == 'null'
                  ? element.compte_vente
                  : this.info.tva[tvaSelectedIndex].compte_vente;

              this.info.tva[tvaSelectedIndex].compte_marchandise =
                this.info.tva[tvaSelectedIndex].compte_marchandise == null ||
                this.info.tva[tvaSelectedIndex].compte_marchandise == 'null'
                  ? element.compte_marchandise
                  : this.info.tva[tvaSelectedIndex].compte_marchandise;

              this.info.tva[tvaSelectedIndex].compte_prestation =
                this.info.tva[tvaSelectedIndex].compte_prestation == null ||
                this.info.tva[tvaSelectedIndex].compte_prestation == 'null'
                  ? element.compte_prestation
                  : this.info.tva[tvaSelectedIndex].compte_prestation;
            }
          } else {
            this.info.tva.push({
              valeur: element.valeur,
              compte_vente: element.compte_vente,
              compte_achat: element.compte_achat,
              compte_marchandise: element.compte_marchandise,
              compte_prestation: element.compte_prestation,
              is_active: element.is_active
            });
          }
        });
      }
    },
    resetModal() {
      this.filiale_id = null;
      this.error = null;
      this.info = null;
    },
    deleteTva(indexD) {
      this.info.tva = this.info.tva.filter((i, index) => index != indexD);
    },
    handelValidData() {
      this.$emit('validDataCompta', this.info);
      this.handelModalInfo('hide');
    }
  }
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.v-dialog.custom-vuetify-dialog-info-conta.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
  top: 30px !important;
  position: absolute !important;
}
.custom-vuetify-dialog-info-conta {
  .card-style {
    border-radius: 10px;
    border: 1px solid #b5b5b5;
    padding: 28px;
    margin-top: 16px;
    margin-bottom: 20px;
    position: relative;

    .title-card {
      position: absolute;
      top: -12px;
      font-size: 12px;
      background-color: #fff;
      padding-left: 5px;
      color: rgb(0 0 0 / 41%);
    }
  }
}
</style>
